<template>
  <v-card>
    <v-progress-linear style="position: absolute;" v-show="loadingSession" :indeterminate="true" class="ma-0"></v-progress-linear>
    <v-card-text class="print-full-width print-font">
      <v-container fluid grid-list-sm pa-0 v-if="sessionDetailed">
        <v-layout row wrap>
          <v-flex xs7>
            <span class="headline">{{ sessionDetailed.clientFullname }}</span> <br />
            <span class="subheading"> Medicaid: {{ sessionDetailed.medicaid }} | DOB: {{ sessionDetailed.dob | moment("utc", "MM/DD/YYYY") }} | Record: {{ sessionDetailed.clientCode }}</span>
          </v-flex>
          <v-flex xs5 class="text-xs-right">
            <span class="title grey--text text--darken-2">{{ sessionDetailed.sessionStart | moment("utc", "LL") }}</span>
          </v-flex>
          <v-flex xs12>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs6>
            <v-layout row wrap>
              <v-flex class="body-2 text-xs-right" xs4>Provider:</v-flex>
              <v-flex xs8 v-if="session.user"
                >{{ session.user.firstname }} {{ session.user.lastname }} <small>({{ session.user.licenseNo }})</small></v-flex
              >
              <v-flex class="body-2 text-xs-right" xs4>Time IN:</v-flex>
              <v-flex xs8>
                <v-icon color="green" small>fa-sign-in-alt</v-icon>
                {{ sessionDetailed.sessionStart | moment("LT") }}
              </v-flex>
              <v-flex class="body-2 text-xs-right" xs4>Time OUT:</v-flex>
              <v-flex xs8>
                <v-icon color="red" small>fa-sign-out-alt</v-icon>
                {{ sessionDetailed.sessionEnd | moment("LT") }}
              </v-flex>
              <v-flex class="body-2 text-xs-right" xs4>Units:</v-flex>
              <v-flex xs8>
                <v-icon small>fa-star</v-icon>
                {{ sessionDetailed.totalUnits.toLocaleString() }}
                <v-icon small>fa-clock</v-icon>
                {{ (sessionDetailed.totalUnits / 4).toLocaleString() }}
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs6>
            <v-layout row wrap>
              <v-flex class="body-2 text-xs-right" xs4>Pos:</v-flex>
              <v-flex xs8>
                <span class="text-no-wrap text-truncate">{{ sessionDetailed.pos }}</span>
              </v-flex>
              <v-flex class="body-2 text-xs-right" xs4>Session type:</v-flex>
              <v-flex xs8>{{ sessionDetailed.sessionType }}</v-flex>
              <v-flex class="body-2 text-xs-right" xs4>Service:</v-flex>
              <v-flex xs8>{{ sessionDetailed.hcpcs }} ({{ sessionDetailed.description }})</v-flex>
              <!-- <v-flex class="body-2 text-xs-right" xs4>Caregiver:</v-flex>
              <v-flex xs8>
                <span v-if="sessionDetailed.caregiver">{{ sessionDetailed.caregiver.caregiverFullname }}</span>
              </v-flex> -->
            </v-layout>
          </v-flex>

          <!-- <v-subheader>Session notes</v-subheader> -->
          <v-flex xs12>
            <v-card flat>
              <v-divider></v-divider>
              <!-- <v-subheader>Session notes:</v-subheader> -->
              <v-card-text v-html="session.sessionNote.notes"></v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12>
            <table class="table-horizontal" width="100%">
              <tr class="no-page-break grey lighten-3" v-if="!noDataBehaviorCollection || !noDataReplacementCollection">
                <td class="font-weight-medium text-xs-right">DATA COLLECTION</td>
                <td></td>
              </tr>
              <tr class="no-page-break" v-if="!noDataBehaviorCollection">
                <td class="font-weight-medium text-xs-right" width="30%" style="vertical-align: top;">
                  Target Behaviors to Decrease:
                </td>
                <td class="pl-1">
                  <table class="table-print">
                    <tr v-for="b in collection.collectBehaviors" :key="b.sessionCollectBehaviorV2Id">
                      <td width="50%">
                        {{ b.behavior.problemBehaviorDescription }}
                      </td>
                      <td width="50%">
                        {{ getTotalBehaviors(b) }}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr class="no-page-break" v-if="!noDataReplacementCollection">
                <td class="font-weight-medium text-xs-right" width="30%" style="vertical-align: top;">
                  Replacement Programs:
                </td>
                <td class="pl-1">
                  <table class="table-print">
                    <tr v-for="b in collection.collectReplacements" :key="b.sessionCollectReplacementV2Id">
                      <td width="50%">
                        {{ b.replacement.replacementProgramDescription }}
                      </td>
                      <td width="50%">
                        {{ getTotalReplacement(b) }}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </v-flex>

          <v-layout v-if="session.userId" row wrap px-3>
            <v-flex xs12>
              <small>Electronic Signature:</small>
            </v-flex>
            <v-flex xs6>
              <sign :userId="session.userId" :date="signDate"></sign>
            </v-flex>
            <v-flex v-if="hasLeadSign && sessionExtraInfo.lead.userId" xs6>
              <sign :userId="sessionExtraInfo.lead.userId" :date="signDate"></sign>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import sessionServicesApi from "@/services/api/SessionServices";
import Sign from "@/components/shared/Sign";

export default {
  props: {
    sessionId: {
      type: Number,
      required: true
    }
  },

  components: {
    Sign
  },

  computed: {
    hasLeadSign() {
      return this.session.user.userId !== this.sessionExtraInfo.lead.userId;
    },
    noDataBehaviorCollection() {
      const noData = this.collection.collectBehaviors.filter(s => s.noData);
      return noData.length == this.collection.collectBehaviors.length;
    },
    noDataReplacementCollection() {
      const noData = this.collection.collectReplacements.filter(s => s.noData);
      return noData.length == this.collection.collectReplacements.length;
    }
  },

  data() {
    return {
      loadingSession: false,
      sessionDetailed: null,
      sessionExtraInfo: {
        lead: {
          userId: null
        }
      },
      session: {
        sessionType: 0,
        sessionNote: {
          caregiverId: null,
          caregiverNote: null,
          notes: null
        },
        user: {
          userId: null
        },
        sessionSupervisionNote: {
          workWith: 0
        }
      },
      collection: {
        collectBehaviors: [],
        collectReplacements: []
      },
      signDate: null
    };
  },

  mounted() {
    if (!this.sessionId) this.close();
    // this.$store.commit("SET_ACTIVE_SESSION", this.sessionId);
    this.loadSessionData();
  },

  methods: {
    async loadSessionData() {
      try {
        this.loadingSession = true;
        let sessionDetailed = await sessionServicesApi.getSessionDetailed(this.sessionId);
        let s1 = this.$moment(sessionDetailed.sessionStart).local();
        let s2 = this.$moment(sessionDetailed.sessionEnd).local();
        sessionDetailed.sessionStart = s1;
        sessionDetailed.sessionEnd = s2;
        this.sessionDetailed = sessionDetailed;
        this.session = await sessionServicesApi.getSession(this.sessionId);
        this.sessionExtraInfo = await sessionServicesApi.getSessionPrintExtraInfo(this.sessionId);
        this.collection.collectBehaviors = await sessionServicesApi.getCollectBehaviors(this.sessionId);
        this.collection.collectReplacements = await sessionServicesApi.getCollectReplacements(this.sessionId);
        this.signDate = this.$moment(s1).add(1, "days"); //Object.assign({}, s1);
        console.log(this.session);
        // this.signDate = this.signDate.add(1, "days");
      } catch (error) {
        this.$toast.error(error.message || error);
      } finally {
        this.loadingSession = false;
      }
    },

    getTotalBehaviors(b) {
      if (b.noData) return "No data collected";
      if (!b.behavior.isPercent) return b.total;
      let total = b.total;
      let completed = b.completed != null ? b.completed : 0;
      let percent = total == 0 ? 0 : (completed / total) * 100;
      return `${percent.toFixed(0)}%`;
    },

    getTotalReplacement(c) {
      if (c.noData) return "No data collected";
      let total = c.total;
      let completed = c.completed;
      let percent = total == 0 ? 0 : (completed / total) * 100;
      return `${percent.toFixed(0)}%`;
    }
  }
};
</script>
